import React from "react"
import { Routes, Route } from "react-router-dom"
import {
    DashboardPage,
    AboutUs,
    GlobalPromoAndNews,
    ContactUs,
    Careers,
    BisnisDetails,
    PromoAndNews,
    ProductGaleri,
} from "../pages"

export default function MainRoutes(){
    return (
        <Routes>
            <Route path="/" element={<DashboardPage />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/global-promo-and-news" element={<GlobalPromoAndNews />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/career-list" element={<Careers />} />
            <Route path="/details/:id" element={<BisnisDetails />} />
            <Route path="/promo-and-news/:id" element={<PromoAndNews />} />
            <Route path="/product-category/:id" element={<ProductGaleri />} />
        </Routes>
    )
}