import axios from 'axios';

const API_KEY = 'YOUR_GOOGLE_API_KEY';
const URL = `https://translation.googleapis.com/language/translate/v2?key=${API_KEY}`;

export const translateText = async (text, targetLang = 'en') => {
//   try {
//     const response = await axios.post(URL, {
//       q: text,
//       target: targetLang,
//     });
//     return response.data.data.translations[0].translatedText;
//   } catch (error) {
//     console.error('Error translating text:', error);
//     return text;
//   }
  return `${text}`;
};
