import React, {useEffect,useState} from 'react'
import { useNavigate } from 'react-router-dom';
import {
    Container,
    Box,
    Grid,
    Card,
    CardContent,
    CardMedia,
    Paper,
    Typography,
    Pagination,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import SliderProductCategory from '../../components/SliderProductCategory/SliderProductCategory';
import SliderSocialMedia from '../../components/SliderSocialMedia/SliderSocialMedia';
// Images
import bg_1 from '../../assets/images/bg_1.jpeg';
import food_1 from '../../assets/images/food_png/1.png';
import food_2 from '../../assets/images/food_png/2.png';
import food_3 from '../../assets/images/food_png/3.png';
import img_1 from '../../assets/images/fnb/food_1.png';
//services//
import DynamicModuleManagementServices from '../../services/DynamicProsperityModuleManagement.service'
//REDUX//
import { useDispatch } from "react-redux";
import { setOtherNews } from "../../redux/actions/app.action";
//UTILS//
import {encryptData,decryptData} from '../../utils/GeneralHelper.js'
//UTILS//

function BisnisDetailFNBPage() {
    let { id } = useParams();
    const Navigate = useNavigate()
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
  
    const [pageData, setPageData] = useState([]);
    const [newsData, setNewsData] = useState([]);
  
    const [totalPage, setTotalPage] = useState(0);
    const [totalData, setTotalData] = useState(0);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value));
      setPage(1);
    };

    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page on component mount
    }, []);

    useEffect(() => {
      if(id){
        getModuleData(id)
      }
    }, [id]);
  
    const getModuleData = async (id) => {
      setIsLoading(true)
      try{
          let params = {
            id:id,
            orderData:'id',
            orderDirection:'ASC',
            table:'bisnis'
          }
          const data = await DynamicModuleManagementServices.getModule(params)
          if(data.data.length>0){
              setPageData([...data.data])
          }
          let paramsNews = {
              page:page,
              perPage:rowsPerPage,
              orderData:'id',
              orderDirection:'DESC',
              bisnis_id:decryptData(id),
              table:'news'
          }
          const news = await DynamicModuleManagementServices.getModule(paramsNews)
          if(news.data.length>0){
              setNewsData([...news.data])
              dispatch(setOtherNews([...news.data]))
              const total = Math.ceil(news.pagination['total'] / parseFloat(rowsPerPage));
              await setTotalPage(total);
              setTotalData(news.pagination['total'])
          }
      } catch(error){
          alert(`There is and error`)
          console.warn(`There is and error`, error);
      } finally{
          setIsLoading(false)
      }
    }

    const getLimitedText = (originalText) => {
        let limitedText = originalText.substring(0, 300) + '...';
        return limitedText
    }
    const HtmlTextDisplay = (originalText) => {
      
        // Create a temporary div element to parse HTML
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = `${originalText}`;
      
        // Extract text from paragraphs and list items with style="text-align:left;"
        let extractedText = '';
        const elements = tempDiv.querySelectorAll('p');
        elements.forEach(element => {
          extractedText += element.textContent.trim() + ' ';
        });
      
        // Trim extracted text to 100 characters
        const limitedText = extractedText.slice(0, 200);
      
        // Render the limited texta
        return (
          <div>
            <p>{limitedText}...</p>
          </div>
        );
    };

    return (
        <Box sx={{minHeight:'95vh',backgroundColor:'#F9F9F9'}}>
            <Box sx={{
                position:'relative',
                height:'100vh',
            }}>
                <Box 
                    style={{
                        position:'absolute',
                        bottom:0,
                        left:0,
                        backgroundColor:'rgba(0, 0, 0, 0.3)',
                        height:'100%',
                        width:'100%'
                    }}
                    display={"flex"}
                    justifyContent={"flex-start"}
                    alignItems={"flex-end"}
                >
                    <Box 
                        sx={{
                            width:{ xs: '100vw', sm: '100vw', md: '100vw', lg: '40vw', xl: '40vw' },
                            padding:{ xs: 5, sm: 5, md: 10, lg: 20, xl: 20 } 
                        }}
                    >
                        <Typography gutterBottom variant="h3" component="div" textAlign={"left"} sx={{color:'#fff'}} ml={1}>
                            {
                                pageData[0]?.nama_bisnis
                            }
                        </Typography>
                        <Typography gutterBottom variant="h5" component="div" textAlign={"left"} sx={{color:'#fff'}} ml={1}>
                            {
                                pageData[0]?.detail_bisnis
                            }
                        </Typography>
                    </Box>
                </Box>
                <img 
                    src={`${process.env.REACT_APP_API_URL}/prosperity-file/${pageData[0]?.header_image}`} 
                    style={{
                        width:'100%',
                        height:'100%',
                        objectFit:'cover',
                    }} 
                />
            </Box>
            {/* <Box 
                sx={{
                    backgroundColor:'#f5f5f5',
                    position:'relative',
                    width:'100%',
                    height:'350px',
                }} 
            >
                <Box 
                    sx={{
                        width:'100%',
                        height:'200px',
                        backgroundColor:'#fff',
                        position:'absolute',
                        bottom:50,
                        zIndex:1
                    }}
                />
                <Box
                    sx={{
                        position:'absolute',
                        width:'100%',
                        top:0,
                        zIndex:2
                    }}
                >
                    <Container maxWidth="xl">
                        <Box px={10}>
                            <Box
                                display={"flex"}
                                flexDirection={'row'}
                                justifyContent={'space-between'}
                                sx={{
                                    width:'100%',
                                    height:'100%',
                                }}
                            >
                                <Box
                                    sx={{
                                        width:'30%',
                                        height:'300px',
                                    }}
                                >
                                    <Box
                                        display={"flex"}
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                        sx={{
                                            width:'100%',
                                            height:'100%',
                                        }}
                                    >
                                        <img 
                                            src={food_1}
                                            style={{
                                                width:'100%',
                                            }} 
                                        />
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        width:'30%',
                                        height:'300px',
                                    }}
                                >
                                    <Box
                                        display={"flex"}
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                        sx={{
                                            width:'100%',
                                            height:'100%',
                                        }}
                                    >
                                        <img 
                                            src={food_2}
                                            style={{
                                                width:'100%',
                                            }} 
                                        />
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        width:'30%',
                                        height:'300px',
                                    }}
                                >
                                    <Box
                                        display={"flex"}
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                        sx={{
                                            width:'100%',
                                            height:'100%',
                                        }}
                                    >
                                        <img 
                                            src={food_3}
                                            style={{
                                                width:'100%',
                                            }} 
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Container>
                </Box>
            </Box> */}
            <Box 
                sx={{
                    backgroundColor:'#f5f5f5',
                }} 
                pt={5}
            >
                <Box>
                    {
                        pageData.length>0&&
                        <SliderProductCategory data={pageData[0]}/>
                    }
                </Box>
            </Box>
            <Box 
                sx={{
                    backgroundColor:'#f5f5f5',
                }} 
                pt={5}
            >
                <Box>
                    {
                        pageData.length>0&&
                        <SliderSocialMedia data={pageData[0]}/>
                    }
                </Box>
            </Box>
            <Box 
                sx={{
                    backgroundColor:'#f5f5f5',
                }} 
                pb={5}
            >
                <Box>
                    <Container maxWidth="xl">
                        {
                            newsData.length> 0 ?
                            <>
                                <Box
                                    display={"flex"}
                                    flexDirection={'row'}
                                    flexWrap={'wrap'}
                                    sx={{
                                        width:'100%',
                                        height:'100%',
                                    }}
                                >
                                    {
                                        newsData.map((data) =>
                                            <>
                                                <Box
                                                    sx={{
                                                        width:{ xs: '100%', sm: '100%', md: '50%', lg: '50%', xl: '50%' },
                                                    }}
                                                    p={1}
                                                >
                                                    <Box
                                                        display={"flex"}
                                                        flexDirection={'row'}
                                                        // justifyContent={'center'}
                                                        alignItems={'stretch'}
                                                        sx={{
                                                            backgroundColor:'#fff',
                                                            borderRadius:2
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                width:'60%',
                                                            }}
                                                            p={2}
                                                        >
                                                            <Typography gutterBottom variant="h5" component="div" textAlign={"left"}>
                                                                {data.news_title}
                                                            </Typography>
                                                            <Typography variant="body2" color="text.secondary" textAlign={"left"}>
                                                                {
                                                                    HtmlTextDisplay(data.news_content)
                                                                }
                                                            </Typography>
                                                            <Box
                                                                sx={{
                                                                    backgroundColor:'#000000',
                                                                    color:'#ffffff',
                                                                    borderRadius:1,
                                                                    cursor:'pointer',
                                                                    width:'110px'
                                                                }}
                                                                px={1}
                                                                mt={2}
                                                                onClick={()=>{Navigate(`/promo-and-news/${encryptData(data.id)}`)}} 
                                                            >
                                                                <Typography variant="subtitle1" sx={{fontWeight:"bold"}}>Read More</Typography>
                                                            </Box>
                                                        </Box>
                                                        <Box
                                                            display={"flex"}
                                                            justifyContent={'center'}
                                                            alignItems={'center'}
                                                            sx={{
                                                                width:'40%',
                                                            }}
                                                        >
                                                            <img 
                                                                src={`${process.env.REACT_APP_API_URL}/prosperity-file/${data.news_image_header}`}
                                                                style={{
                                                                    width:'100%',
                                                                    objectFit:'cover',
                                                                }} 
                                                            />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </>
                                        )
                                    }
                                </Box>
                                <Box py={2}>
                                    <Pagination count={totalPage} page={page} variant="outlined" shape="rounded" onChange={(event,page) => handleChangePage(event,page)} />
                                </Box>
                            </>
                            :
                            <Box
                                display={"flex"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                sx={{
                                    width:'100%',
                                    height:'200px'
                                }}
                            >
                                <Typography variant="body2" color="text.secondary" textAlign={"left"} sx={{fontWeight:'bold'}}>
                                    No Promo / News data available yet . . .
                                </Typography>
                            </Box>
                        }
                    </Container>
                </Box>
            </Box>
        </Box>
    );
}

export default BisnisDetailFNBPage;
  