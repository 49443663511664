import React, { useEffect, useState, useRef } from 'react';
import DOMPurify from 'dompurify';
import moment from 'moment';
import {
    Tabs,
    Tab,
    Container, 
    Box,
    Typography,
    TextField,
    Button,
    Grid,
    Paper,
    IconButton,
    Modal,
    RadioGroup,
    Radio,
    FormControlLabel,
    Select,
    MenuItem,
    CircularProgress,
    Autocomplete,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import {
    Uploader,
    useToaster,
    Input,
    InputNumber,
    SelectPicker,
} from "rsuite";

function MainPageModal({modalHandle,modalData}) {
    const toaster = useToaster();
    const [detailData, setDetailData] = useState(null);

    useEffect(()=>{
        setDetailData(modalData)
    },[modalData])

    const renderHTML = (value) => {
        const sanitizedHtmlString = DOMPurify.sanitize(value);
        return sanitizedHtmlString
    }

    return (
        <Modal
          open={true}
        >
            <Box
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                sx={{
                    height:'100vh',
                    width:'100vw'
                }}
            >
                <Container maxWidth="lg">
                    <Paper>
                        <Box p={2}>
                            <Box
                                display={'flex'}
                                justifyContent={'space-between'}
                                alignItems={'center'}
                                flexDirection={'row'}
                                p={1}
                            >
                                <Box>
                                    <Typography gutterBottom variant="h5" component="div" textAlign={"left"} sx={{fontWeight:'bold'}}>
                                        {detailData?.product_name}
                                    </Typography>
                                </Box>
                                <Box>
                                    <IconButton aria-label="close" onClick={modalHandle}>
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                            <Box
                                display={'flex'}
                                flexDirection={'row'}
                                p={1}
                            >
                                <Box
                                    display={"flex"}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                    sx={{
                                        width:'40%',
                                    }}
                                >
                                    <img 
                                        src={`${process.env.REACT_APP_API_URL}/prosperity-file/${detailData?.product_image}`}
                                        style={{
                                            width:'100%',
                                            objectFit:'cover',
                                        }} 
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        height:'50vh',
                                        width:'60%',
                                        overflow:'auto',
                                        padding:5
                                    }}
                                    py={1}
                                >
                                    <div dangerouslySetInnerHTML={{ __html: renderHTML(detailData?.product_description) }} />
                                </Box>
                            </Box>
                        </Box>
                    </Paper>
                </Container>
            </Box>
        </Modal>
    );
}

export default MainPageModal;
