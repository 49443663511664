import React, {useEffect,useState} from 'react'
import {
    Container,
    Box,
    Grid,
    Card,
    CardContent,
    CardMedia,
    CardActionArea,
    Typography,
    CircularProgress,
} from '@mui/material';
import { AccessAlarm, ThreeDRotation } from '@mui/icons-material';
import XIcon from '@mui/icons-material/X';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { useNavigate } from 'react-router-dom';
//css
import './Footer.css';
//media
import instagram_1 from '../../assets/images/icon/instagram.png';
import tiktok_1 from '../../assets/images/icon/tiktok.png';
//UTILS//
import {encryptData,decryptData} from '../../utils/GeneralHelper.js'
//UTILS//
//services//
import DynamicModuleManagementServices from '../../services/DynamicProsperityModuleManagement.service'

const FooterComponent = () => {
    const [isLoading, setIsLoading] = useState(false);
  
    const [pageData, setPageData] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page on component mount
        getModuleData()
    }, []);
  
    const getModuleData = async () => {
        setIsLoading(true)
        try{
            let params = {
              id:encryptData(1),
              orderData:'id',
              orderDirection:'ASC',
              table:'company'
            }
            const data = await DynamicModuleManagementServices.getModule(params)
            if(data.data.length>0){
                setPageData([...data.data])
            }
        } catch(error){
            alert(`There is and error`)
            console.warn(`There is and error`, error);
        } finally{
            setIsLoading(false)
        }
    }
    return(
        <Box 
        display={"flex"}
        sx={{backgroundColor:'#161C2D'}}
        py={15}
        >
            <Container maxWidth="xl">
                <Box
                    p={2}
                    sx={{color:'#ffffff'}}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <Box>
                                <Box>
                                    <Typography gutterBottom variant="h6" component="div" textAlign={"left"}>
                                        ©2024, Hak Cipta
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography gutterBottom variant="h6" component="div" textAlign={"left"}>
                                        Alamat :
                                    </Typography>
                                </Box>
                                {
                                    isLoading ?
                                    <CircularProgress />
                                    :
                                    pageData.length>0?
                                        <Box>
                                            <Typography gutterBottom variant="subtitle2" component="div" textAlign={"left"}>
                                            {pageData[0]?.company_address}
                                            </Typography>
                                        </Box>
                                    :
                                    <></>
                                }
                                {/* <Box>
                                    <Typography gutterBottom variant="h6" component="div" textAlign={"left"}>
                                    Social Media Kami:
                                    </Typography>
                                </Box>
                                <Box 
                                    display={"flex"}
                                    flexDirection={"column"}
                                    justifyContent={'center'}
                                    alignItems={'start'}
                                >
                                    <Box 
                                        display={"flex"}
                                        flexDirection={"row"}
                                        alignItems={'center'}
                                        py={1}
                                    >
                                        <img src={instagram_1} width={30} height={30} />
                                        <Typography pl={1} gutterBottom variant="subtitle2" component="div" textAlign={"left"}>
                                        prosperity_grouppik
                                        </Typography>
                                    </Box>
                                    <Box 
                                        display={"flex"}
                                        flexDirection={"row"}
                                        alignItems={'center'}
                                        py={1}
                                    >
                                        <img src={tiktok_1} width={30} height={30} />
                                        <Typography pl={1} gutterBottom variant="subtitle2" component="div" textAlign={"left"}>
                                        prosperity_grouppik
                                        </Typography>
                                    </Box>
                                </Box> */}
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}/>
                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            {/* <Box>
                                <Box>
                                    <Typography gutterBottom variant="h6" component="div" textAlign={"left"}>
                                        Alamat :
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography gutterBottom variant="subtitle2" component="div" textAlign={"left"}>
                                    Gold Coast Office, Tower Liberty Lantai 10 Unit A,
                                    </Typography>
                                    <Typography gutterBottom variant="subtitle2" component="div" textAlign={"left"}>
                                    Pantai Indah Kapuk
                                    </Typography>
                                </Box>
                            </Box> */}
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>
    )
}

export default FooterComponent