import { 
SET_USER_DETAILS,
SET_DYNAMIC_MODULE,
SET_OTHER_NEWS
} from "../actions/types";

const initialState = {
    userDetails : {},
    dynamicModule : {},
    otherNews:[]
}

const AppReducer = (state = initialState, action) => {
    switch (action.type){
        case SET_USER_DETAILS:
            return {
                ...state,
                userDetails:action.user
            }
        case SET_DYNAMIC_MODULE:
            return {
                ...state,
                dynamicModule:action.module
            }
        case SET_OTHER_NEWS:
            return {
                ...state,
                otherNews:action.news
            }
        default:
            return state
    }
}
export default AppReducer