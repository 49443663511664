import React, {useEffect,useState} from 'react'
import { useNavigate } from 'react-router-dom';
import {
    Container,
    Box,
    Grid,
    Card,
    CardContent,
    CardMedia,
    Paper,
    Typography,
    Pagination,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import Slider from "react-slick";
import Hero from '../../components/HeroSlider/Hero';
import HeroRsuite from '../../components/HeroRsuite/HeroRsuite';
import Section1 from '../../components/Section1/Section1';
import Section2 from '../../components/Section2/Section2';
// Images
import bg_1 from '../../assets/images/bg_1.jpeg';
import food_1 from '../../assets/images/food_png/1.png';
import food_2 from '../../assets/images/food_png/2.png';
import food_3 from '../../assets/images/food_png/3.png';
import img_1 from '../../assets/images/fnb/food_1.png';
//services//
import DynamicModuleManagementServices from '../../services/DynamicProsperityModuleManagement.service'
//REDUX//
import { useDispatch } from "react-redux";
import { setOtherNews } from "../../redux/actions/app.action";
//UTILS//
import {encryptData,decryptData} from '../../utils/GeneralHelper.js'
//UTILS//

function GlobalPromoAndNews() {
    let { id } = useParams();
    const Navigate = useNavigate()
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
  
    const [pageData, setPageData] = useState([]);
    const [newsData, setNewsData] = useState([]);
  
    const [totalPage, setTotalPage] = useState(0);
    const [totalData, setTotalData] = useState(0);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value));
      setPage(1);
    };

    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page on component mount
    }, []);

    useEffect(() => {
        getModuleData()
    }, []);
  
    const getModuleData = async (id) => {
      setIsLoading(true)
      try{
          let paramsNews = {
              page:page,
              perPage:rowsPerPage,
              orderData:'id',
              orderDirection:'DESC',
              table:'news'
          }
          const news = await DynamicModuleManagementServices.getModule(paramsNews)
          if(news.data.length>0){
              setNewsData([...news.data])
              dispatch(setOtherNews([...news.data]))
              const total = Math.ceil(news.pagination['total'] / parseFloat(rowsPerPage));
              await setTotalPage(total);
              setTotalData(news.pagination['total'])
          }
      } catch(error){
          alert(`There is and error`)
          console.warn(`There is and error`, error);
      } finally{
          setIsLoading(false)
      }
    }

    const getLimitedText = (originalText) => {
        let limitedText = originalText.substring(0, 300) + '...';
        return limitedText
    }
    const HtmlTextDisplay = (originalText) => {
      
        // Create a temporary div element to parse HTML
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = `${originalText}`;
      
        // Extract text from paragraphs and list items with style="text-align:left;"
        let extractedText = '';
        const elements = tempDiv.querySelectorAll('p');
        elements.forEach(element => {
          extractedText += element.textContent.trim() + ' ';
        });
      
        // Trim extracted text to 100 characters
        const limitedText = extractedText.slice(0, 200);
      
        // Render the limited texta
        return (
          <div>
            <p>{limitedText}...</p>
          </div>
        );
    };

    return (
        <Box sx={{minHeight:'70vh',backgroundColor:'#f5f5f5'}}>
            <Box 
                py={5}
            >
                <Box>
                    <Container maxWidth="xl" sx={{pt:10,pb:5}}>
                        <Box sx={{color:'#000'}}>
                            <Typography gutterBottom variant="h3" component="div" textAlign={"left"}>
                                Promo And News
                            </Typography>
                        </Box>
                        {
                            newsData.length> 0 ?
                            <>
                                <Box
                                    display={"flex"}
                                    flexDirection={'row'}
                                    flexWrap={'wrap'}
                                    sx={{
                                        width:'100%',
                                        height:'100%',
                                    }}
                                >
                                    {
                                        newsData.map((data) =>
                                            <>
                                                <Box
                                                    sx={{
                                                        width:{ xs: '100%', sm: '100%', md: '50%', lg: '50%', xl: '50%' },
                                                    }}
                                                    p={1}
                                                >
                                                    <Box
                                                        display={"flex"}
                                                        flexDirection={'row'}
                                                        // justifyContent={'center'}
                                                        alignItems={'stretch'}
                                                        sx={{
                                                            backgroundColor:'#fff',
                                                            borderRadius:2
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                width:'60%',
                                                            }}
                                                            p={2}
                                                        >
                                                            <Typography gutterBottom variant="h5" component="div" textAlign={"left"}>
                                                                {data.news_title}
                                                            </Typography>
                                                            <Typography variant="body2" color="text.secondary" textAlign={"left"}>
                                                                {
                                                                    HtmlTextDisplay(data.news_content)
                                                                }
                                                            </Typography>
                                                            <Box
                                                                sx={{
                                                                    backgroundColor:'#000000',
                                                                    color:'#ffffff',
                                                                    borderRadius:1,
                                                                    cursor:'pointer',
                                                                    width:'110px'
                                                                }}
                                                                px={1}
                                                                mt={2}
                                                                onClick={()=>{Navigate(`/promo-and-news/${encryptData(data.id)}`)}} 
                                                            >
                                                                <Typography variant="subtitle1" sx={{fontWeight:"bold"}}>Read More</Typography>
                                                            </Box>
                                                        </Box>
                                                        <Box
                                                            display={"flex"}
                                                            justifyContent={'center'}
                                                            alignItems={'center'}
                                                            sx={{
                                                                width:'40%',
                                                            }}
                                                        >
                                                            <img 
                                                                src={`${process.env.REACT_APP_API_URL}/prosperity-file/${data.news_image_header}`}
                                                                style={{
                                                                    width:'100%',
                                                                    objectFit:'cover',
                                                                }} 
                                                            />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </>
                                        )
                                    }
                                </Box>
                                <Box py={2}>
                                    <Pagination count={totalPage} page={page} variant="outlined" shape="rounded" onChange={(event,page) => handleChangePage(event,page)} />
                                </Box>
                            </>
                            :
                            <Box
                                display={"flex"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                sx={{
                                    width:'100%',
                                    height:'200px'
                                }}
                            >
                                <Typography variant="body2" color="text.secondary" textAlign={"left"} sx={{fontWeight:'bold'}}>
                                    No Promo / News data available yet . . .
                                </Typography>
                            </Box>
                        }
                    </Container>
                </Box>
            </Box>
        </Box>
    );
}

export default GlobalPromoAndNews;
  