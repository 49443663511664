import React, { useEffect, useState } from 'react';
import { translateText } from './services/translateService';
import { useTranslation } from './contex/TranslationContext';

import './App.css';
import 'rsuite/dist/rsuite.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import NavHeader from './components/NavHeader/NavHeader';
import Footer from './components/Footer/Footer';
import AppRoute from './routes/index'
import {
  Box
} from '@mui/material';

function App() {
  const [textElements, setTextElements] = useState([]);
  const [originalText, setOriginalText] = useState([]);
  const { setLanguage, translateAllText } = useTranslation();

  const handleTranslate = async () => {
    const elements = document.querySelectorAll('[data-translate]');
    const texts = Array.from(elements).map((el) => el.innerText);
    const newTranslations = await translateAllText(texts);
    elements.forEach((el, idx) => {
      el.innerText = newTranslations[texts[idx]];
    });
    setLanguage('en');
  };

  const resetTranslate = async () => {
    const elements = document.querySelectorAll('[data-translate]');
    elements.forEach((el, idx) => {
      el.innerText = originalText[idx];
    });
    setLanguage('id');
  };

  useEffect(() => {
    const elements = document.querySelectorAll('[data-translate]');
    const texts = Array.from(elements).map((el) => el.innerText);
    setOriginalText([...texts])
    setTextElements(elements);
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <NavHeader handleTranslate={handleTranslate} resetTranslate={resetTranslate} />
      </header>
      <Box 
      // sx={{mt:'5vh'}}
      >
        <AppRoute />
      </Box>
      <Footer />
    </div>
  );
}

export default App;
