import React, { useEffect , useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import {
    Container,
    Box,
    Grid,
    Card,
    CardContent,
    CardMedia,
    Typography
} from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import img_1 from '../../assets/images/fnb/food_1.png';
//services//
import DynamicModuleManagementServices from '../../services/DynamicProsperityModuleManagement.service.js'
//services//
//UTILS//
import {encryptData,decryptData} from '../../utils/GeneralHelper.js'
//UTILS//

function SliderExperimental({data}) {
  const [isLoading, setIsLoading] = useState(false);

  const [pageData, setPageData] = useState([]);

  const Navigate = useNavigate()
  let sliderRef = useRef(null);
  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };
  let sliderRefSmall = useRef(null);
  const nextSmall = () => {
    sliderRefSmall.slickNext();
  };
  const previousSmall = () => {
    sliderRefSmall.slickPrev();
  };
  const [activeSlide, setActiveSlide] = useState(0);
  const [settings, setSettings] = useState({
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    beforeChange: (current, next) => {
      setActiveSlide(next);
    },
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    draggable: false,
    swipe: false,  
  });
  const [settingsSmall, setSettingsSmall] = useState({
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => {
      setActiveSlide(next);
    },
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    draggable: false,
    swipe: false,  
  });
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: 'none'}}
        onClick={onClick}
      />
    );
  }
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: 'none' }}
        onClick={onClick}
      />
    );
  }

  useEffect(() => {
    if(data.id){
      getModuleData(data?.id)
    }
  }, [data]);

  const getModuleData = async (id) => {
    setIsLoading(true)
    try{
        let params = {
          orderData:'id',
          orderDirection:'ASC',
          bisnis_id:id,
          table:'social_media'
        }
        const data = await DynamicModuleManagementServices.getModule(params)
        if(data.data.length>0){
            setPageData([...data.data])
        }
    } catch(error){
        alert(`There is and error`)
        console.warn(`There is and error`, error);
    } finally{
        setIsLoading(false)
    }
  }

  const openLinkInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <Box sx={{backgroundColor:'#f5f5f5'}} py={5}>
      <Box sx={{backgroundColor:'#f5f5f5'}}>
        <Container maxWidth="xl">
          <Box
            sx={{
              position:'relative'
            }}
            py={5}
          >
            <Box
              sx={{
                display:'flex',
                alignItems:'start',
                justifyContent:'center',
                position:'absolute',
                top:0,
                height:'100%',
                width:'100%',
              }}
            >
              <Box
                sx={{
                  position:'absolute',
                  top:-10,
                  backgroundColor:'#000000',
                  color:'#ffffff',
                  width:'auto',
                  borderRadius:5
                }}
                px={7}
              >
                <Typography variant="subtitle1" sx={{fontWeight:"bold"}}>Our Social Media</Typography>
              </Box>
            </Box>
            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block' } }}>
              {
                  pageData.length===0?
                  <Box
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      sx={{
                          width:'100%',
                          height:'200px'
                      }}
                  >
                      <Typography variant="body2" color="text.secondary" textAlign={"left"} sx={{fontWeight:'bold'}}>
                          No Social Media data available yet . . .
                      </Typography>
                  </Box>
                  :
                  <>
                  <Slider
                    ref={slider => {
                      sliderRef = slider;
                    }}
                    {...settings}
                  >
                    {
                      pageData.map((item,key) =>
                        <Box
                          sx={{
                            width:'100%',
                            height:'100%',
                            cursor:'pointer'
                          }}
                          onClick={()=> openLinkInNewTab(`${item.social_media_link}`)}
                          key={key}
                        >
                            <Box
                              display={"flex"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              flexDirection={'column'}
                              sx={{
                                width:'100%',
                                height:'100%',
                              }}
                            >
                              <img src={`${process.env.REACT_APP_API_URL}/prosperity-file/${item.social_media_image}`} width={100} height={100} />
                            </Box>
                            <Box
                              display={"flex"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              flexDirection={'column'}
                              sx={{
                                width:'100%',
                              }}
                            >
                              <Typography pt={1} gutterBottom variant="h5" component="div" textAlign={"center"} style={{fontWeight:'bold'}}>
                                {item.social_media_title}
                              </Typography>
                            </Box>
                        </Box>
                      )
                    }
                  </Slider>
                  {
                    activeSlide !== 0 &&
                    <Box
                      sx={{
                        display:'flex',
                        alignItems:'center',
                        position:'absolute',
                        top:0,
                        left:{md: 0, lg: 0, xl: -75},
                        height:'100%',
                        cursor:'pointer',
                      }}
                      onClick={previous}
                    >
                      <NavigateBeforeIcon sx={{ fontSize: {md: 50, lg: 50, xl: 150}  }} />
                    </Box>
                  }
                  {
                    pageData.length > settings.slidesToShow && activeSlide !== pageData.length - 5 &&
                    <Box
                      sx={{
                        display:'flex',
                        alignItems:'center',
                        position:'absolute',
                        top:0,
                        right:{md: 0, lg: 0, xl: -75},
                        height:'100%',
                        cursor:'pointer',
                      }}
                      onClick={next}
                    >
                      <NavigateNextIcon sx={{ fontSize: {md: 50, lg: 50, xl: 150} }} />
                    </Box>
                  }
                  </>
              }
            </Box>
            <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none', lg: 'none', xl: 'none' } }}>
              {
                  pageData.length===0?
                  <Box
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      sx={{
                          width:'100%',
                          height:'200px'
                      }}
                  >
                      <Typography variant="body2" color="text.secondary" textAlign={"left"} sx={{fontWeight:'bold'}}>
                          No Product Category data available yet . . .
                      </Typography>
                  </Box>
                  :
                  <>
                  <Slider
                    ref={slider => {
                      sliderRefSmall = slider;
                    }}
                    {...settingsSmall}
                  >
                    {
                      pageData.map((item,key) =>
                        <Box
                          sx={{
                            width:'100%',
                            height:'100%',
                            cursor:'pointer'
                          }}
                          onClick={()=> openLinkInNewTab(`${item.social_media_link}`)}
                          key={key}
                        >
                            <Box
                              display={"flex"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              flexDirection={'column'}
                              sx={{
                                width:'100%',
                                height:'100%',
                              }}
                            >
                              <img src={`${process.env.REACT_APP_API_URL}/prosperity-file/${item.social_media_image}`} width={100} height={100} />
                            </Box>
                            <Box
                              display={"flex"}
                              justifyContent={"center"}
                              alignItems={"center"}
                              flexDirection={'column'}
                              sx={{
                                width:'100%',
                              }}
                            >
                              <Typography pt={1} gutterBottom variant="h5" component="div" textAlign={"center"} style={{fontWeight:'bold'}}>
                                {item.social_media_title}
                              </Typography>
                            </Box>
                        </Box>
                      )
                    }
                  </Slider>
                  {
                    activeSlide !== 0 &&
                    <Box
                      sx={{
                        display:'flex',
                        alignItems:'center',
                        position:'absolute',
                        top:0,
                        left:-0,
                        height:'100%',
                        cursor:'pointer',
                      }}
                      onClick={previousSmall}
                    >
                      <NavigateBeforeIcon sx={{ fontSize: 50 }} />
                    </Box>
                  }
                  {
                    pageData.length > settingsSmall.slidesToShow && activeSlide !== 1 &&
                    <Box
                      sx={{
                        display:'flex',
                        alignItems:'center',
                        position:'absolute',
                        top:0,
                        right:-0,
                        height:'100%',
                        cursor:'pointer',
                      }}
                      onClick={nextSmall}
                    >
                      <NavigateNextIcon sx={{ fontSize: 50 }} />
                    </Box>
                  }
                  </>
              }
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}

export default SliderExperimental;
