//main//
import React, { useEffect, useState } from 'react';
//main//
import { Carousel } from 'rsuite';
import Wrapper from "../UI/Wrapper/Wrapper";
import Title from "../UI/Title/Title";
import Subtitle from "../UI/Subtitle/Subtitle";
import {
    CircularProgress,
    Box,
    Typography
} from '@mui/material';
import ReactPlayer from 'react-player'
//css
import './HeroRsuite.css';
//services//
import DynamicModuleManagementServices from '../../services/DynamicProsperityModuleManagement.service'


const HeroRsuiteComponent = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [pageData, setPageData] = useState([]);

    useEffect(()=>{
        getModuleData()
    },[])

    const getModuleData = async () => {
        setIsLoading(true)
        try{
            let params = {
                orderData:'id',
                orderDirection:'ASC',
                table:'landing_carousel'
            }
            const data = await DynamicModuleManagementServices.getModule(params)
            if(data.data.length>0){
                setPageData([...data.data])
            }
        } catch(error){
            alert(`There is and error`)
            console.warn(`There is and error`, error);
        } finally{
            setIsLoading(false)
        }
    }

    return(
        isLoading ?
        <CircularProgress />
        :
        <Carousel 
            autoplay 
            shape={"dot"} 
            autoplayInterval={60000}
            style={{
                height:'100vh'
            }}
        >
            {
                pageData.length>0&&pageData.map((data)=>
                    <Box sx={{position:'relative'}}>
                        {
                            data.item_type == 'image'?
                            <img 
                                src={`${process.env.REACT_APP_API_URL}/prosperity-file/${data.source_link}`} 
                                style={{
                                    width:'100%',
                                    height:'100%',
                                    objectFit:'cover',
                                }} 
                            />
                            :
                            <div className="video-container">
                            <video className="video-cover" autoPlay loop muted>
                                <source 
                                    src={`${process.env.REACT_APP_API_URL}/prosperity-file/${data.source_link}`} 
                                />
                                Your browser does not support the video tag.
                            </video>
                            </div>
                        }
                        <Box 
                            style={{
                                position:'absolute',
                                bottom:0,
                                left:0,
                                backgroundColor:'rgba(0, 0, 0, 0.3)',
                                height:'100%',
                                width:'100%'
                            }}
                            display={"flex"}
                            justifyContent={"flex-start"}
                            alignItems={"flex-end"}
                        >
                            <Box 
                                sx={{
                                    width:{ xs: '100vw', sm: '100vw', md: '100vw', lg: '40vw', xl: '40vw' },
                                    padding:{ xs: 5, sm: 5, md: 10, lg: 20, xl: 20 } 
                                }}
                            >
                                <Typography gutterBottom variant="h3" component="div" textAlign={"left"} sx={{color:'#fff'}} ml={1}>
                                    {data.title_carousel}
                                </Typography>
                                <Typography gutterBottom variant="h5" component="div" textAlign={"left"} sx={{color:'#fff'}} ml={1}>
                                    {data.description_carousel}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                )
            }
        </Carousel>
    )
}

export default HeroRsuiteComponent;