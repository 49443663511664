import { 
SET_USER_DETAILS,
SET_DYNAMIC_MODULE,
SET_OTHER_NEWS
} from "./types";

export const setUser = (user) => ({
    type: SET_USER_DETAILS,
    user
})

export const setDynamicModule = (module) => ({
    type: SET_DYNAMIC_MODULE,
    module
})

export const setOtherNews = (news) => ({
    type: SET_OTHER_NEWS,
    news
})