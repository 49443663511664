import React, { createContext, useContext, useState } from 'react';
import { translateText } from '../services/translateService';

// 1. Membuat Context
const TranslationContext = createContext();

// 2. Hook untuk menggunakan Context
// export const useTranslation = () => useContext(TranslationContext);
export const useTranslation = () => {
    const context = useContext(TranslationContext);
    // console.warn('useTranslation context:', context);
    return context;
  };

// 3. Provider untuk menyediakan Context ke komponen anak
export const TranslationProvider = ({ children }) => {
  const [translations, setTranslations] = useState({});
  const [language, setLanguage] = useState('id'); // Default language is Indonesian

  // Fungsi untuk menerjemahkan semua teks
  const translateAllText = async (texts) => {
    const translatedTexts = await Promise.all(
        texts.map((text) => 
        translateText(text, 'en')
    ));
    const newTranslations = texts.reduce((acc, text, idx) => {
      acc[text] = translatedTexts[idx];
      return acc;
    }, {});
    setTranslations(newTranslations);
    return newTranslations;
  };

  // Menyediakan nilai untuk Context
  return (
    <TranslationContext.Provider value={{ translations, setLanguage, translateAllText }}>
        {/* {console.warn('TranslationProvider values:', { translations, setLanguage, translateAllText })} */}
      {children}
    </TranslationContext.Provider>
  );
};
