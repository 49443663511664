import React, {useEffect,useState} from 'react'
import {
  Box,
  Typography
} from '@mui/material';
import Hero from '../../components/HeroSlider/Hero';
import HeroRsuite from '../../components/HeroRsuite/HeroRsuite';
import Section1 from '../../components/Section1/Section1';
import Section2 from '../../components/Section2/Section2';

import SliderExperimental from '../../components/SliderExperimental/SliderExperimental';
import SliderFNB from '../../components/SliderFNB/SliderFNB';
import SliderFMCG from '../../components/SliderFMCG/SliderFMCG';
import SliderFashion from '../../components/SliderFashion/SliderFashion';
import History from '../../components/History/History';
//services//
import DynamicModuleManagementServices from '../../services/DynamicProsperityModuleManagement.service'

function DashboardPage() {

    const [isLoading, setIsLoading] = useState(false);

    const [pageData, setPageData] = useState([]);

    useEffect(() => {
        getModuleData()
        window.scrollTo(0, 0); // Scrolls to the top of the page on component mount
    }, []);

    const getModuleData = async () => {
        setIsLoading(true)
        try{
            let params = {
                orderData:'id',
                orderDirection:'ASC',
                table:'bisnis_category'
            }
            const data = await DynamicModuleManagementServices.getModule(params)
            if(data.data.length>0){
                setPageData([...data.data])
            }
        } catch(error){
            alert(`There is and error`)
            console.warn(`There is and error`, error);
        } finally{
            setIsLoading(false)
        }
    }
    
    return (
        <>
            {/* <Hero /> */}
            <HeroRsuite />
            {
                pageData.length>0&&pageData.map((data)=>
                    <SliderExperimental data={data} />
                )
            }
            {/* <SliderFNB />
            <SliderFMCG />
            <SliderFashion /> */}
            {/* <History /> */}
        </>
    );
}

export default DashboardPage;
