import React, {useEffect,useState} from 'react'
import DOMPurify from 'dompurify';
import {
    Container,
    Box,
    Grid,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography
} from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// Images
import bg_1 from '../../assets/images/bg_1.jpeg';
//services//
import DynamicModuleManagementServices from '../../services/DynamicProsperityModuleManagement.service'

function AboutUsPage() {
    const [isLoading, setIsLoading] = useState(false);
    const [pageData, setPageData] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page on component mount
    }, []);

    useEffect(() => {
        getModuleData()
    }, []);
  
    const getModuleData = async (id) => {
      setIsLoading(true)
      try{
          let params = {
              orderData:'id',
              orderDirection:'DESC',
              table:'career'
          }
          const result = await DynamicModuleManagementServices.getModule(params)
          setPageData([...result.data])
      } catch(error){
          alert(`There is and error`)
          console.warn(`There is and error`, error);
      } finally{
          setIsLoading(false)
      }
    }

    const renderHTML = (value) => {
        const sanitizedHtmlString = DOMPurify.sanitize(value);
        return sanitizedHtmlString
    }

    return (
        <Box sx={{minHeight:'95vh',backgroundColor:'#F9F9F9'}}>
            {/* <Box sx={{position:'absolute',width:'100%'}}>
                <img src={bg_1} height={'350px'} width={'100%'} style={{objectFit:'cover'}}/>
            </Box> */}
            <Box py={5}>
                <Container maxWidth="xl" sx={{pt:10,pb:5}}>
                    <Box sx={{color:'#000'}}>
                        <Typography gutterBottom variant="h3" component="div" textAlign={"left"}>
                            Career List
                        </Typography>
                    </Box>
                    <Box p={{ xs: 1, sm: 1, md: 10, lg: 10, xl: 10 }} sx={{backgroundColor:'#fff',minHeight:'50vh'}}>
                        <Box py={2}>
                            <Typography gutterBottom variant="h6" component="div" textAlign={"center"}>
                                OPEN POSITIONS
                            </Typography>
                            <Typography gutterBottom variant="h4" component="div" textAlign={"center"}>
                                Current job openings
                            </Typography>
                        </Box>
                        <Box>
                            {
                                pageData&&pageData.length>0&&pageData.map((data) => 
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ArrowDropDownIcon />}
                                            aria-controls="panel2-content"
                                            id="panel2-header"
                                        >
                                            <Box
                                                display={"flex"}
                                                flexDirection={"column"}
                                            >
                                                <Box>
                                                    <Typography gutterBottom variant="h5" component="div" textAlign={"left"}>
                                                        {data.career_title}
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <Typography gutterBottom variant="body2" component="div" textAlign={"left"}>
                                                        {data.career_description}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div dangerouslySetInnerHTML={{ __html: renderHTML(data.career_detail) }} />
                                        </AccordionDetails>
                                    </Accordion>
                                )
                            }
                        </Box>
                    </Box>
                </Container>
            </Box>
        </Box>
    );
}

export default AboutUsPage;
  