import React, {useEffect,useState} from 'react'
import DOMPurify from 'dompurify';
import {
    Container,
    Box,
    Grid,
    Typography,
    CircularProgress,
} from '@mui/material';
// Images
import bg_1 from '../../assets/images/bg_1.jpeg';
//UTILS//
import {encryptData,decryptData} from '../../utils/GeneralHelper.js'
//UTILS//
//services//
import DynamicModuleManagementServices from '../../services/DynamicProsperityModuleManagement.service'

function AboutUsPage() {
    const [isLoading, setIsLoading] = useState(false);
  
    const [pageData, setPageData] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page on component mount
        getModuleData()
    }, []);
  
    const getModuleData = async () => {
        setIsLoading(true)
        try{
            let params = {
              id:encryptData(1),
              orderData:'id',
              orderDirection:'ASC',
              table:'company'
            }
            const data = await DynamicModuleManagementServices.getModule(params)
            if(data.data.length>0){
                setPageData([...data.data])
            }
        } catch(error){
            alert(`There is and error`)
            console.warn(`There is and error`, error);
        } finally{
            setIsLoading(false)
        }
    }

    const renderHTML = (value) => {
        const sanitizedHtmlString = DOMPurify.sanitize(value);
        return sanitizedHtmlString
    }

    return (
        <Box sx={{minHeight:'95vh',backgroundColor:'#F9F9F9'}}>
            {/* <Box sx={{position:'absolute',width:'100%'}}>
                <img src={bg_1} height={'350px'} width={'100%'} style={{objectFit:'cover'}}/>
            </Box> */}
            <Box py={5}>
                <Container maxWidth="xl" sx={{pt:10,pb:5}}>
                    <Box sx={{color:'#000'}}>
                        <Typography gutterBottom variant="h3" component="div" textAlign={"left"}>
                            About Us
                        </Typography>
                    </Box>
                    {
                        isLoading ?
                        <CircularProgress />
                        :
                        pageData.length>0?
                        <Box p={10} sx={{backgroundColor:'#fff',minHeight:'50vh'}}>
                            <div dangerouslySetInnerHTML={{ __html: renderHTML(pageData[0]?.company_history) }} />
                        </Box>
                        :
                        <></>
                    }
                </Container>
            </Box>
        </Box>
    );
}

export default AboutUsPage;
  