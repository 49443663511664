import React, {useEffect,useState} from 'react'
import {
    Container,
    Box,
    Grid,
    Typography,
    CircularProgress,
} from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import PlaceIcon from '@mui/icons-material/Place';
import EmailIcon from '@mui/icons-material/Email';
import Map from '../../components/Map/Map';
import DOMPurify from 'dompurify';
// Images
import bg_1 from '../../assets/images/bg_1.jpeg';
//UTILS//
import {encryptData,decryptData} from '../../utils/GeneralHelper.js'
//UTILS//
//services//
import DynamicModuleManagementServices from '../../services/DynamicProsperityModuleManagement.service'

function ContactUsPage() {
    const [isLoading, setIsLoading] = useState(false);
  
    const [pageData, setPageData] = useState([]);
    const [detailData, setPageDetailData] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page on component mount
        getModuleData()
    }, []);
  
    const getModuleData = async () => {
        setIsLoading(true)
        try{
            let params = {
              id:encryptData(1),
              orderData:'id',
              orderDirection:'ASC',
              table:'contact'
            }
            const data = await DynamicModuleManagementServices.getModule(params)
            if(data.data.length>0){
                setPageData([...data.data])
            }
            let paramsNews = {
                contact_id:1,
                table:'contact_item'
            }
            const detailDatas = await DynamicModuleManagementServices.getModule(paramsNews)
            if(detailDatas.data.length>0){
                setPageDetailData([...detailDatas.data])
            }
        } catch(error){
            alert(`There is and error`)
            console.warn(`There is and error`, error);
        } finally{
            setIsLoading(false)
        }
    }

    const renderHTML = (value) => {
        const sanitizedHtmlString = DOMPurify.sanitize(value);
        return sanitizedHtmlString
    }

    const getCoordinate = (value) => {
        const centerCoordinate = {
            lat: 0,
            lng: 0
        }
        let rawCoordinate = value.split(',')
        if(rawCoordinate.length>0){
            centerCoordinate.lat = parseFloat(rawCoordinate[0])
            centerCoordinate.lng = parseFloat(rawCoordinate[1])
        }
        return centerCoordinate
    }

    const getDetailItemIcon = (value) => {
        if(value == 'phone'){
            return <PhoneIcon sx={{fontSize: 40}} />
        } else if(value == 'email'){
            return <EmailIcon sx={{fontSize: 40}} />
        } else if(value == 'address'){
            return <PlaceIcon sx={{fontSize: 40}} />
        } else {
            return ''
        }
    }

    return (
        <Box sx={{minHeight:'95vh',backgroundColor:'#F9F9F9'}}>
            {/* <Box sx={{position:'absolute',width:'100%'}}>
                <img src={bg_1} height={'350px'} width={'100%'} style={{objectFit:'cover'}}/>
            </Box> */}
            <Box py={5}>
                <Container maxWidth="xl" sx={{pt:10,pb:5}}>
                    <Box sx={{color:'#000'}}>
                        <Typography gutterBottom variant="h3" component="div" textAlign={"left"}>
                            Contact Us
                        </Typography>
                    </Box>
                    <Box p={10} sx={{backgroundColor:'#fff'}}>
                        {
                            isLoading ?
                            <CircularProgress />
                            :
                            pageData.length>0?
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Box
                                        display={"flex"}
                                        alignItems={"center"}
                                    >
                                        <Box>
                                            <Typography gutterBottom variant="h4" component="div" textAlign={"left"}>
                                                Contact details
                                            </Typography>
                                            <div dangerouslySetInnerHTML={{ __html: renderHTML(pageData[0]?.contact_detail) }} />
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Box
                                            py={2}
                                        >
                                            {
                                                detailData&&detailData.length>0&&detailData.map((item,index) =>
                                                    <Box
                                                        display={"flex"}
                                                        flexDirection={"row"}
                                                        py={1}
                                                        key={index}
                                                    >
                                                        <Box
                                                            display={"flex"}
                                                            alignItems={'center'}
                                                            sx={{height:'100%'}}
                                                            mr={3}
                                                        >
                                                            {getDetailItemIcon(item.contact_item_type)}
                                                        </Box>
                                                        <Box>
                                                            <Box>
                                                                <Typography variant="subtitle1" color="text.secondary" textAlign={"left"}>
                                                                    {item.contact_item_title}
                                                                </Typography>
                                                            </Box>
                                                            <Box>
                                                                <Typography variant="subtitle1" color="text.secondary" textAlign={"left"}>
                                                                    {item.contact_item_details}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                )
                                            }
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Box
                                        display={"flex"}
                                        alignItems={"center"}
                                        justifyContent={"flex-end"}
                                        sx={{height:'100%',width:'100%'}}
                                    >
                                        <Map center={getCoordinate(pageData[0]?.contact_coordinate)} />
                                    </Box>
                                </Grid>
                            </Grid>
                            :
                            <></>
                        }
                    </Box>
                </Container>
            </Box>
        </Box>
    );
}

export default ContactUsPage;
  