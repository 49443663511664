import React, {useEffect,useState} from 'react'
import { useNavigate } from 'react-router-dom';
//REDUX//
import { useSelector } from 'react-redux';
//REDUX//
import DOMPurify from 'dompurify';
import moment from 'moment';
import {
    Container,
    Box,
    Grid,
    Typography
} from '@mui/material';
import { useParams } from 'react-router-dom';
import Hero from '../../components/HeroSlider/Hero';
import HeroRsuite from '../../components/HeroRsuite/HeroRsuite';
import Section1 from '../../components/Section1/Section1';
import Section2 from '../../components/Section2/Section2';
// Images
import bg_1 from '../../assets/images/bg_1.jpeg';
//services//
import DynamicModuleManagementServices from '../../services/DynamicProsperityModuleManagement.service'
//css
import './PromoAndNews.css';
//UTILS//
import {encryptData,decryptData} from '../../utils/GeneralHelper.js'
//UTILS//

function PromoAndNewsPage() {
    const otherNewsRaw = useSelector((state)=> state.AppReducer.otherNews)
    let { id } = useParams();
    const Navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false);
  
    const [pageData, setPageData] = useState([]);
    const [otherNews, setOtherNews] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page on component mount
    }, []);

    useEffect(() => {
      if(id){
        let otherNewsData = otherNewsRaw.filter((x) => x.id !== parseFloat(decryptData(id)))
        getModuleData(id)
        setOtherNews([...otherNewsData])
      }
    }, [id]);
  
    const getModuleData = async (id) => {
      setIsLoading(true)
      try{
          let params = {
            id:id,
            orderData:'id',
            orderDirection:'ASC',
            table:'news'
          }
          const data = await DynamicModuleManagementServices.getModule(params)
          if(data.data.length>0){
              setPageData([...data.data])
          }
      } catch(error){
          alert(`There is and error`)
          console.warn(`There is and error`, error);
      } finally{
          setIsLoading(false)
      }
    }

    const renderHTML = (value) => {
        const sanitizedHtmlString = DOMPurify.sanitize(value);
        return sanitizedHtmlString
    }

    const getLimitedText = (originalText) => {
        let limitedText = originalText.substring(0, 300) + '...';
        return limitedText
    }
    const HtmlTextDisplay = (originalText) => {
      
        // Create a temporary div element to parse HTML
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = `${originalText}`;
      
        // Extract text from paragraphs and list items with style="text-align:left;"
        let extractedText = '';
        const elements = tempDiv.querySelectorAll('p');
        elements.forEach(element => {
          extractedText += element.textContent.trim() + ' ';
        });
      
        // Trim extracted text to 100 characters
        const limitedText = extractedText.slice(0, 200);
      
        // Render the limited texta
        return (
          <div>
            <p>{limitedText}...</p>
          </div>
        );
    };

    return (
        <Box sx={{minHeight:'95vh',backgroundColor:'#F9F9F9'}}>
            <Box mt={'50px'}>
                <Container maxWidth="xl">
                    <Box py={5} px={{ xs: 1, sm: 1, md: 4, lg: 8, xl: 8 }} sx={{backgroundColor:'#fff',minHeight:'50vh'}}>
                        <Box
                            display={"flex"}
                            flexDirection={'row'}
                            flexWrap={'wrap'}
                        >
                            <Box
                                sx={{
                                    width:otherNews.length > 0?{ xs: '100%', sm: '100%', md: '60%', lg: '60%', xl: '60%' }:'100%'
                                }}
                            >
                                <Box sx={{width:'100%',position:'relative'}}>
                                    <Box
                                        sx={{
                                            width:'100%',
                                            aspectRatio:'16/9'
                                        }}
                                    >
                                        <img 
                                            src={
                                                `${process.env.REACT_APP_API_URL}/prosperity-file/${pageData[0]?.news_image_header}`
                                            } 
                                            width={'100%'} s
                                            tyle={{
                                                objectFit:'cover'
                                            }}
                                        />
                                    </Box>
                                    <Box 
                                        style={{
                                            position:'absolute',
                                            bottom:0,
                                            left:0,
                                            backgroundColor:'rgba(0, 0, 0, 0.4)',
                                            height:'100%',
                                            width:'100%'
                                        }}
                                        display={"flex"}
                                        justifyContent={"flex-start"}
                                        alignItems={"flex-end"}
                                    >
                                        <Box 
                                            sx={{
                                                width:{ xs: '100vw', sm: '100vw', md: '100vw', lg: '40vw', xl: '40vw' },
                                                padding:{ xs: 1, sm: 1, md: 4, lg: 8, xl: 8 } ,
                                                // backgroundColor:'rgba(0, 0, 0, 0.8)',
                                            }}
                                        >
                                            <Typography gutterBottom variant="h3" component="div" textAlign={"left"} sx={{color:'#fff'}} ml={1}>
                                                {
                                                    pageData[0]?.news_title
                                                }
                                            </Typography>
                                            <Typography variant="body2" textAlign={"left"} sx={{color:'#fff',fontWeight:'bold'}} ml={1}>
                                                {`Created at ${moment(pageData[0]?.created_date).format("DD MMMM YYYY")}`}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box mt={2}>
                                    <div dangerouslySetInnerHTML={{ __html: renderHTML(pageData[0]?.news_content) }} />
                                </Box>
                            </Box>
                            {
                                otherNews.length > 0 &&
                                <Box
                                    sx={{
                                        width:{ xs: '100%', sm: '100%', md: '38%', lg: '38%', xl: '38%' }
                                    }}
                                    px={1}
                                >
                                    <>
                                        <Box
                                            display={"flex"}
                                            flexDirection={'column'}
                                        >
                                            {
                                                otherNews.map((data) =>
                                                    <Box
                                                        display={"flex"}
                                                        flexDirection={'row'}
                                                        alignItems={'stretch'}
                                                        className="card-news"
                                                    >
                                                        <Box
                                                            sx={{
                                                                width:'60%',
                                                            }}
                                                        >
                                                            <Typography gutterBottom variant="h5" component="div" textAlign={"left"}>
                                                                {data.news_title}
                                                            </Typography>
                                                            <Typography variant="body2" color="text.secondary" textAlign={"left"}>
                                                                {HtmlTextDisplay(data.news_content)}
                                                            </Typography>
                                                            <Box
                                                                sx={{
                                                                    backgroundColor:'#000000',
                                                                    color:'#ffffff',
                                                                    borderRadius:1,
                                                                    cursor:'pointer',
                                                                    width:'110px'
                                                                }}
                                                                px={1}
                                                                mt={2}
                                                                onClick={()=>{Navigate(`/promo-and-news/${encryptData(data.id)}`)}} 
                                                            >
                                                                <Typography variant="subtitle1" sx={{fontWeight:"bold"}}>Read More</Typography>
                                                            </Box>
                                                        </Box>
                                                        <Box
                                                            display={"flex"}
                                                            justifyContent={'center'}
                                                            alignItems={'center'}
                                                            sx={{
                                                                width:'40%',
                                                            }}
                                                        >
                                                            <img 
                                                                src={`${process.env.REACT_APP_API_URL}/prosperity-file/${data.news_image_header}`}
                                                                style={{
                                                                    width:'100%',
                                                                    objectFit:'cover',
                                                                }} 
                                                            />
                                                        </Box>
                                                    </Box>
                                                )
                                            }
                                        </Box>
                                    </>
                                </Box>
                            }
                        </Box>
                    </Box>
                </Container>
            </Box>
        </Box>
    );
}

export default PromoAndNewsPage;
  